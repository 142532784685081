import { gql } from "@apollo/client";
import { BriefFieldsWithSubmission } from "creators/fragments/brief";
import { MoneyFields } from "creators/fragments/money";

export const BriefTeaserRequirementsFields = gql`
  fragment BriefTeaserRequirementsFields on BriefRequirements {
    assets
    featuring
    location
    propsOrItems
  }
`;

export const BriefTeaserTeaserFields = gql`
  fragment BriefTeaserTeaserFields on BriefTeaser {
    creatorsDeliverables
    introduction
    requirements {
      ...BriefTeaserRequirementsFields
    }
  }
  ${BriefTeaserRequirementsFields}
`;

export const BriefTeaserFields = gql`
  fragment BriefTeaserFields on Brief {
    ...BriefFieldsWithSubmission
    liveActionSurveyLink
    teaser {
      ...BriefTeaserTeaserFields
    }
  }
  ${BriefFieldsWithSubmission}
  ${BriefTeaserTeaserFields}
  ${MoneyFields}
`;

export const GET_BRIEF_TEASER = gql`
  query GetBriefTeaser($id: ID!, $previewHash: String) {
    brief(id: $id, previewHash: $previewHash) {
      ...BriefTeaserFields
    }
  }
  ${BriefTeaserFields}
`;

export default {
  GET_BRIEF_TEASER,
};
