import { FC, PropsWithChildren } from "react";
import styled from "styled-components";

interface IHeadingAppearance {
  $appearance: "display" | "large" | "medium" | "small" | "body";
}

const StyledHeading = styled.span<IHeadingAppearance>`
  ${({ $appearance }) => {
    switch ($appearance) {
      case "display":
        return `
          font: var(--vui-type-display-shorthand);
          letter-spacing: var(--vui-type-display-spacing);
        `;
      case "large":
        return `
          font: var(--vui-type-title-large-shorthand);
          letter-spacing: var(--vui-type-title-large-spacing);
        `;
      case "medium":
        return `
          font: var(--vui-type-title-medium-shorthand);
          letter-spacing: var(--vui-type-title-medium-spacing);
        `;
      case "small":
        return `
          font: var(--vui-type-title-small-shorthand);
          letter-spacing: var(--vui-type-title-small-spacing);
        `;
      case "body":
        return `
          font: var(--vui-type-title-body-shorthand);
          letter-spacing: var(--vui-type-title-body-spacing);
        `;
    }
  }}
`;

export interface IProps {
  appearance: "display" | "large" | "medium" | "small" | "body";
  className?: string;
  id?: string;
  level?: "1" | "2" | "3" | "4" | "5" | "6";
  style?: React.CSSProperties;
}

const Heading: FC<PropsWithChildren<IProps>> = ({
  appearance,
  children,
  className,
  id,
  level,
  style,
}) => {
  return (
    <StyledHeading
      $appearance={appearance}
      as={level && `h${level}`}
      className={className}
      id={id}
      style={style}
    >
      {children}
    </StyledHeading>
  );
};

export default Heading;
