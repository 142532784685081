import SquareImage from "creators/components/SquareImage";
import Tag, { Color } from "creators/components/Tag";
import IDS from "creators/constants/ids";
import { BriefType } from "creators/types/graphql";
import textualize from "creators/utils/textualize";
import { forwardRef } from "react";
import { BriefName, Container, Header, Text } from "./styles";

export interface IProps {
  brandImage: string;
  brandName: string;
  briefName: string;
  briefType: BriefType;
  className?: string;
  id?: string;
}

const BriefHeader = forwardRef<HTMLElement, IProps>(
  ({ brandImage, brandName, briefName, briefType, className, id }, ref) => (
    <Container className={className} id={id} ref={ref}>
      <SquareImage
        alt={brandName}
        id={IDS.BRIEF_HEADER.BRAND.IMAGE}
        src={brandImage}
      />
      <div>
        <Header id={IDS.BRIEF_HEADER.BRAND.NAME}>{brandName}</Header>
        <Text id={IDS.BRIEF_HEADER.BRIEF.SUMMARY}>
          <BriefName id={IDS.BRIEF_HEADER.BRIEF.TITLE}>{briefName}</BriefName>
        </Text>
        {briefType === BriefType.MotionGraphics ? (
          <Tag color={Color.Blue} id={IDS.BRIEF_HEADER.TYPE.REMIX}>
            {textualize("common.briefType.remix")}
          </Tag>
        ) : (
          <Tag color={Color.Green} id={IDS.BRIEF_HEADER.TYPE.CGC}>
            {textualize("common.briefType.cgc")}
          </Tag>
        )}
      </div>
    </Container>
  ),
);

export default BriefHeader;
