// TODO: Replace with VUI Modal/Dialog
import StandardModal from "creators/components/Modal/StandardModal";
import styled from "styled-components";
import BREAKPOINTS from "vui/constants/breakpoints";

export const StyledStandardModal = styled(StandardModal)`
  background: rgb(var(--vui-color-surface-primary));
  display: flex;
  flex-direction: column;
  gap: var(--vui-spacing-4);
  min-width: 33vw;
  padding: var(--vui-spacing-4);
  width: 100%;
  z-index: 100;

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_LG}) {
    padding: var(--vui-spacing-8);
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
