import Countdown from "creators/components/Countdown";
import Tag, { Color } from "creators/components/Tag";
import DATE_FORMATS from "creators/constants/dateFormats";
import IDS from "creators/constants/ids";
import WarningIcon from "creators/images/icons/warning-inverted.svg";
import { BriefState, BriefType, SubmissionState } from "creators/types/graphql";
import textualize from "creators/utils/textualize";
import { format } from "date-fns";
import { useCallback, useState } from "react";
import formatNumberToCurrency from "shared/utils/formatNumberToCurrency";
import {
  ApplicationsClosed,
  BottomBar,
  BrandName,
  BriefImage,
  BriefTitle,
  DidNotSubmit,
  DivContainer,
  LinkContainer,
  Status,
  StatusText,
  StyledIcon,
  Summary,
  TopBar,
} from "./styles";

export interface IProps {
  brandID: string;
  brandImage: string;
  brandName: string;
  briefID: string;
  briefState: BriefState;
  briefTitle: string;
  briefType: BriefType;
  className?: string;
  creatorSelectionDate?: Date;
  paymentAmount: number;
  paymentCurrencyCode: string;
  slug: string;
  submissionState?: SubmissionState;
  submittedAt?: Date;
}

// TODO: Update to expect only the `submissionState` that will be returned after the
// `liveBriefsV2` update but also for the other `BriefCard` instances (Previous Briefs)
export const BriefCard: React.FC<React.PropsWithChildren<IProps>> = ({
  brandImage,
  brandName,
  briefID,
  briefState,
  briefTitle,
  briefType,
  className,
  creatorSelectionDate,
  paymentAmount,
  paymentCurrencyCode,
  slug,
  submissionState,
  submittedAt,
}) => {
  const [applicationsClosed, setApplicationsClosed] = useState(
    briefState === BriefState.Closed ||
      (creatorSelectionDate && creatorSelectionDate <= new Date()),
  );

  const closeApplications = useCallback(() => {
    setApplicationsClosed(true);
  }, []);

  const completed =
    submittedAt && submissionState === SubmissionState.Completed;
  const didNotSubmit =
    !submittedAt && submissionState === SubmissionState.Completed;

  let status;
  if (completed) {
    status = (
      <StatusText>
        {textualize("briefCard.submittedDate", {
          date: format(submittedAt!, DATE_FORMATS.SHORT_DATE),
        })}
      </StatusText>
    );
  } else if (didNotSubmit) {
    status = (
      <DidNotSubmit>
        <StyledIcon component={WarningIcon} />
        {textualize("briefCard.didNotSubmit")}
      </DidNotSubmit>
    );
  } else if (submissionState === SubmissionState.Applied) {
    status = <Tag color={Color.Blue}>{textualize("briefCard.applied")}</Tag>;
  } else if (submissionState === SubmissionState.InviteDeclined) {
    status = (
      <Tag color={Color.Red}>{textualize("briefCard.inviteDeclined")}</Tag>
    );
  } else if (submissionState === SubmissionState.Declined) {
    status = <Tag color={Color.Red}>{textualize("briefCard.notSelected")}</Tag>;
  } else if (applicationsClosed) {
    status = (
      <ApplicationsClosed>
        {textualize("briefCard.applicationsClosed")}
      </ApplicationsClosed>
    );
  } else {
    status = (
      <StatusText>
        {textualize("briefCard.timeToApply", {
          time: creatorSelectionDate ? (
            <Countdown
              date={creatorSelectionDate}
              largestOnly={true}
              onComplete={closeApplications}
            />
          ) : (
            ""
          ),
        })}
      </StatusText>
    );
  }

  const content = (
    <>
      <BriefImage
        alt={textualize("common.logo", { brandName }) as string}
        id={`${IDS.BRIEF_CARD.BRAND_IMAGE}${briefID}`}
        src={brandImage}
      />
      <TopBar>
        <BrandName id={`${IDS.BRIEF_CARD.BRAND_NAME}${briefID}`}>
          {brandName}
        </BrandName>
        <div id={`${IDS.BRIEF_CARD.PAYMENT_AMOUNT}${briefID}`}>
          {formatNumberToCurrency(paymentAmount, "GB", paymentCurrencyCode)}
        </div>
      </TopBar>
      <Summary>
        <BriefTitle id={`${IDS.BRIEF_CARD.TITLE}${briefID}`}>
          {briefTitle}
        </BriefTitle>
      </Summary>
      <BottomBar>
        {briefType === BriefType.MotionGraphics ? (
          <Tag color={Color.Blue} id={`${IDS.BRIEF_CARD.BRIEF_TYPE}${briefID}`}>
            {textualize("common.briefType.remix")}
          </Tag>
        ) : (
          <Tag
            color={Color.Green}
            id={`${IDS.BRIEF_CARD.BRIEF_TYPE}${briefID}`}
          >
            {textualize("common.briefType.cgc")}
          </Tag>
        )}
        <Status id={`${IDS.BRIEF_CARD.APPLICATION_STATE}${briefID}`}>
          {status}
        </Status>
      </BottomBar>
    </>
  );

  if (didNotSubmit) {
    return (
      <DivContainer
        className={className}
        id={`${IDS.BRIEF_CARD.COMPONENT}${briefID}`}
      >
        {content}
      </DivContainer>
    );
  }

  return (
    <LinkContainer
      className={className}
      id={`${IDS.BRIEF_CARD.COMPONENT}${briefID}`}
      to={`/${completed ? "previous-briefs" : "brief"}/${briefID}/${slug}`}
    >
      {content}
    </LinkContainer>
  );
};

export default BriefCard;
