// TODO: Replace with VUI Modal/Dialog
import ModalWrapper from "creators/components/Modal/ModalWrapper";
import textualize from "creators/utils/textualize";
import { FilledButton } from "vui/components/Button";
import Heading from "vui/components/Heading";
import { ButtonWrapper, StyledStandardModal } from "./styles";

const SessionExpiredModal = () => {
  return (
    <ModalWrapper>
      <StyledStandardModal>
        <Heading appearance="medium" level="2">
          {textualize("modals.sessionExpired.heading")}
        </Heading>

        <div>{textualize("modals.sessionExpired.message")}</div>

        <ButtonWrapper>
          <FilledButton forwardedAs="a" href="/login">
            {textualize("modals.sessionExpired.login")}
          </FilledButton>
        </ButtonWrapper>
      </StyledStandardModal>
    </ModalWrapper>
  );
};

export default SessionExpiredModal;
