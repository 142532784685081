import styled from "styled-components";
import Icon from "vui/components/Icon";

export const NavDrawerItemOuter = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: var(--vui-spacing-4);
  height: var(--vui-spacing-12);
  padding: var(--vui-spacing-1) var(--vui-spacing-4);
  position: relative;

  &::before {
    background: none;
    border-radius: var(--vui-shape-corner-full);
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
`;

export const NavDrawerItemIcon = styled(Icon)`
  display: block;
  flex: 1 0 auto;
  height: var(--vui-spacing-6);
  position: relative;
  width: var(--vui-spacing-6);
`;

export const NavDrawerItemLabel = styled.span`
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;
