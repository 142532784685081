import { HTMLAttributes } from "react";
import styled from "styled-components";

export type IProps = HTMLAttributes<HTMLDivElement>;

const BaseSnackbar: React.FC<IProps> = ({ children, className, ...rest }) => (
  <div {...rest} className={className} role="status">
    {children}
  </div>
);

const Snackbar = styled(BaseSnackbar)`
  align-items: center;
  background: rgb(var(--vui-color-inverse-surface));
  border-radius: var(--vui-shape-corner-extra-small);
  box-shadow: var(--vui-shadow-medium);
  color: rgb(var(--vui-color-inverse-on-surface));
  display: flex;
  min-height: var(--vui-spacing-16);
  min-width: var(--vui-spacing-80);
  padding: var(--vui-spacing-3) var(--vui-spacing-4);
  pointer-events: auto;
`;

export default Snackbar;
