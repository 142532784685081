import SquareImage from "creators/components/SquareImage";
import DIMENSIONS from "creators/constants/dimensions";
import FONTS from "creators/constants/fonts";
import { Link } from "react-router-dom";
import Icon from "shared/components/Icon";
import styled, { css } from "styled-components";

const containerStyles = css`
  background: var(--v-color-white);
  border-radius: 0.5rem;
  color: inherit;
  display: grid;
  gap: 0.75rem;
  grid-template-columns: 2rem 1fr;
  padding: var(--v-spacing-4);
  text-decoration: none;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    grid-template-columns: 3rem 1fr;
  }

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    grid-template-columns: 10rem 1fr;
  }
`;

export const LinkContainer = styled(Link)`
  ${containerStyles}

  &:focus,
  &:hover {
    box-shadow: inset 0 0 0 0.125rem var(--v-color-black);
  }
`;

export const DivContainer = styled.div`
  ${containerStyles}
`;

const statusTextStyles = css`
  font-size: 0.75rem;
  font-weight: ${FONTS.WEIGHT.BOLD};

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    font-size: 0.875rem;
  }

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    font-size: 1rem;
  }
`;

export const BriefImage = styled(SquareImage)`
  grid-column: 1;
  grid-row: 1;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    grid-row: 1 / 4;
  }
`;

export const TopBar = styled.div`
  align-items: center;
  display: flex;
  font-size: 1rem;
  font-weight: ${FONTS.WEIGHT.BOLD};
  gap: 0.5rem;
  grid-column: 2;
  justify-content: space-between;
  min-width: 0;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    font-size: 1.125rem;
  }

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    font-size: 1.5rem;
    grid-column: auto / auto;
  }
`;

export const BrandName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Summary = styled.div`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  font-size: 0.75rem;
  grid-column: 1 / 3;
  overflow: hidden;
  word-break: break-word;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    font-size: 0.875rem;
  }

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    font-size: 1rem;
    grid-column: auto / auto;
  }
`;

export const BriefTitle = styled.span`
  font-weight: ${FONTS.WEIGHT.BOLD};
`;

export const BottomBar = styled.div`
  align-items: center;
  display: flex;
  grid-column: 1 / 3;
  justify-content: space-between;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    grid-column: auto / auto;
  }
`;

export const Status = styled.div`
  text-align: right;
`;

export const StatusText = styled.div`
  ${statusTextStyles};
`;

export const ApplicationsClosed = styled.span`
  ${statusTextStyles};
`;

export const DidNotSubmit = styled.div`
  ${statusTextStyles};
  align-items: center;
  display: flex;
`;

export const StyledIcon = styled(Icon)`
  color: var(--v-color-red);
  height: var(--v-icon-size-lg);
  margin-right: var(--v-spacing-2);

  svg {
    height: 100%;
    stroke: var(--v-color-white);
  }
`;
