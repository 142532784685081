import { gql } from "@apollo/client";
import { BrandFields } from "creators/fragments/brand";
import { MoneyFields } from "creators/fragments/money";
import { SubmissionV2Fields } from "creators/fragments/submissionV2";
import { VideoTextBlockFields } from "creators/fragments/videoTextBlock";

export const BriefFields = gql`
  fragment BriefFields on Brief {
    id
    brand {
      ...BrandFields
    }
    creatorSelectionDate
    numberOfCreatorApplications
    numberOfCreators
    slug
    state
    submissionDate
    title
    type
  }
  ${BrandFields}
`;

export const BriefFieldsWithPayment = gql`
  fragment BriefFieldsWithPayment on Brief {
    ...BriefFields
    paymentAmount {
      ...MoneyFields
    }
    paymentDate
    viewerPaymentAmount {
      ...MoneyFields
    }
  }
  ${BriefFields}
  ${MoneyFields}
`;

export const BriefFieldsWithSubmission = gql`
  fragment BriefFieldsWithSubmission on Brief {
    ...BriefFieldsWithPayment
    submission {
      ...SubmissionV2Fields
    }
  }
  ${BriefFieldsWithPayment}
  ${SubmissionV2Fields}
`;

export const BriefLeadFields = gql`
  fragment BriefLeadFields on BriefLead {
    id
    imageURI
    name
  }
`;

export const BriefRequirementsFields = gql`
  fragment BriefRequirementsFields on BriefRequirements {
    audio
    featuring
    location
    propsOrItems
    tone
    use
  }
`;

export const OtherCreatorsFields = gql`
  fragment OtherCreatorsFields on PublicCreator {
    creatorExternalID
    imageURI
    name
    profilePrivate
  }
`;

export const BriefDetailsFields = gql`
  fragment BriefDetailsFields on BriefDetails {
    assetsURI
    beforeYouStart
    creatorsDeliverables
    introduction
    releaseForms
    releaseFormsReturnURI
    requirements {
      ...BriefRequirementsFields
    }
    videoTextBlocks {
      ...VideoTextBlockFields
    }
  }
  ${BriefRequirementsFields}
  ${VideoTextBlockFields}
`;

export const ActiveBriefFields = gql`
  fragment ActiveBriefFields on Brief {
    ...BriefFieldsWithPayment
    briefLead {
      ...BriefLeadFields
    }
    deliveryLink
    details {
      ...BriefDetailsFields
    }
    jobReference
    otherCreators {
      ...OtherCreatorsFields
    }
    shortHash
  }
  ${BriefDetailsFields}
  ${BriefFieldsWithPayment}
  ${BriefLeadFields}
  ${OtherCreatorsFields}
  ${VideoTextBlockFields}
`;

export default {
  ActiveBriefFields,
  BriefFields,
  BriefFieldsWithPayment,
  BriefFieldsWithSubmission,
};
