import { StyledVidsyLogo, Wrapper } from "creators/pages/vui/Error/Error";
import textualize from "creators/utils/textualize";
import { format } from "date-fns";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Heading from "vui/components/Heading";

export interface IProps {
  returnEstimation?: string;
}

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  > {
    h1 {
      margin-bottom: var(--vui-spacing-12);
    }

    div {
      margin-bottom: var(--vui-spacing-8);
      white-space: break-spaces;
    }
  }
`;

export const MaintenanceMode = ({
  returnEstimation: returnEstimationString,
}: IProps) => {
  let returnEstimation: Date | undefined;

  if (returnEstimationString) {
    returnEstimation = new Date(returnEstimationString);
  }

  return (
    <Wrapper>
      <Helmet title={textualize("vuiMaintenanceMode.heading") as string} />

      <StyledVidsyLogo />

      <Content>
        <Heading appearance="large" level="1">
          {textualize("vuiMaintenanceMode.heading")}
        </Heading>

        <div>{textualize("vuiMaintenanceMode.body")}</div>

        {returnEstimation && (
          <>
            <div>
              {textualize("vuiMaintenanceMode.returnEstimation", {
                time: format(returnEstimation, "h:mmaaa"),
              })}
            </div>
          </>
        )}

        <div>{textualize("vuiMaintenanceMode.intercom")}</div>
      </Content>
    </Wrapper>
  );
};

export default MaintenanceMode;
