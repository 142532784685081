import { useMutation, useQuery } from "@apollo/client";
import BlockConductor from "creators/components/Brief/BlockConductor";
import DataError from "creators/components/DataError";
import LoadingDelayed from "creators/components/LoadingDelayed";
import TeaserBanner from "creators/components/TeaserBanner";
import TeaserFooter from "creators/components/TeaserFooter";
import ANALYTICS from "creators/constants/analytics";
import IDS from "creators/constants/ids";
import ChevronLeft from "creators/images/icons/chevron-left.svg";
import APPLY_TO_BRIEF from "creators/mutations/applyToBrief";
import WITHDRAW_BRIEF_APPLICATION from "creators/mutations/withdrawBriefApplication";
import { BriefAnalyticsContext } from "creators/providers/BriefAnalyticsProvider";
import {
  ScreenSize,
  ViewportContext,
} from "creators/providers/ViewportProvider";
import { GET_BRIEF_TEASER } from "creators/queries/GetBriefTeaser";
import { GET_VIEWER } from "creators/queries/GetViewer";
import {
  BriefType,
  ApplyToBriefMutation as IApplyToBrief,
  GetBriefTeaserQuery as IGetBriefTeaser,
  GetViewerQuery as IGetViewer,
  WithdrawBriefApplicationMutation as IWithdrawBriefApplication,
  SubmissionState,
} from "creators/types/graphql";
import textualize from "creators/utils/textualize";
import { useCallback, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router";
import { Block } from "shared/BlockBuilder/global";
import { collateSections, ICollatedSection } from "shared/BlockBuilder/utils";
import { ToastContext } from "shared/components/ToastProvider";
import fireAnalyticsEvent from "shared/utils/fireAnalyticsEvent";
import generateBlockID from "shared/utils/generateBlockID";
import {
  BriefInfo,
  CGCIntroduction,
  CGCResponsiveLayout,
  CGCTeaserSection,
  Introduction,
  ResponsiveLayout,
  StyledBackButton,
  StyledBriefHeader,
  StyledCGCTeaserSummary,
  StyledTeaserSummary,
  StyledWidget,
  Teaser,
  TeaserSection,
} from "./styles";

interface IProps {
  briefID: string;
  className?: string;
  previewHash?: string;
  slug?: string;
}

function BriefTeaser({ briefID, className, previewHash, slug }: IProps) {
  const { briefAnalytics, cleanAnalyticsData, saveAnalyticsData } = useContext(
    BriefAnalyticsContext,
  );

  const { data: userData } = useQuery<IGetViewer>(GET_VIEWER);
  const { data, loading, error } = useQuery<IGetBriefTeaser>(GET_BRIEF_TEASER, {
    variables: { id: briefID, previewHash },
  });

  const [
    applyToBriefMutation,
    { loading: applyBriefApplicationMutationLoading },
  ] = useMutation<IApplyToBrief>(APPLY_TO_BRIEF);
  const [
    withdrawBriefApplicationMutation,
    { loading: withdrawBriefApplicationMutationLoading },
  ] = useMutation<IWithdrawBriefApplication>(WITHDRAW_BRIEF_APPLICATION);

  const size = useContext(ViewportContext);
  const { addToast } = useContext(ToastContext);

  const [displaySurvey, setDisplaySurvey] = useState(false);
  const [successfullyApplied, setSuccessfullyApplied] = useState(false);
  const [successfullyWithdrawn, setSuccessfullyWithdrawn] = useState(false);
  const [teaserViewedEventFired, setTeaserViewedEventFired] = useState(false);

  const showTeaserSurvey =
    data?.brief.type === BriefType.LiveAction &&
    data?.brief.liveActionSurveyLink;

  useEffect(() => {
    if (!data) {
      return;
    }

    saveAnalyticsData(data.brief);
  }, [cleanAnalyticsData, data, saveAnalyticsData]);

  useEffect(() => {
    // Fire analytics event on Brief Teaser load
    // Catch if briefAnalytics is empty to avoid firing event
    if (Object.keys(briefAnalytics).length === 0 || teaserViewedEventFired) {
      return;
    }

    fireAnalyticsEvent(
      ANALYTICS.CATEGORIES.LIVE_BRIEFS,
      ANALYTICS.EVENT.TEASER_VIEWED,
      briefAnalytics,
    );

    setTeaserViewedEventFired(true);
  }, [briefID, briefAnalytics, teaserViewedEventFired]);

  const handleApplicationEvent = useCallback(async () => {
    try {
      const result = await applyToBriefMutation({
        variables: {
          input: {
            id: briefID,
          },
        },
      });

      if (result?.data?.applyToBrief) {
        fireAnalyticsEvent(
          ANALYTICS.CATEGORIES.LIVE_BRIEFS,
          ANALYTICS.EVENT.APPLY_TO_BRIEF,
          briefAnalytics,
        );
        setSuccessfullyApplied(true);
      }
    } catch {
      addToast(
        textualize("liveBriefs.teaser.footer.open.error") as string,
        true,
      );
    }
  }, [addToast, applyToBriefMutation, briefID, briefAnalytics]);

  const handleApplicationClick = useCallback(async () => {
    if (showTeaserSurvey) {
      setDisplaySurvey(true);
      return;
    }

    handleApplicationEvent();
  }, [handleApplicationEvent, showTeaserSurvey]);

  const handleWithdrawClick = useCallback(async () => {
    try {
      const result = await withdrawBriefApplicationMutation({
        variables: {
          input: {
            briefID: briefID,
          },
        },
      });

      if (result.data?.withdrawBriefApplication) {
        fireAnalyticsEvent(
          ANALYTICS.CATEGORIES.LIVE_BRIEFS,
          ANALYTICS.EVENT.BRIEF_WITHDRAW_APPLICATION,
          briefAnalytics,
        );
        setSuccessfullyWithdrawn(true);
        setSuccessfullyApplied(false);
      }
    } catch {
      addToast(
        textualize("liveBriefs.teaser.footer.withdraw.error") as string,
        true,
      );
    }
  }, [addToast, withdrawBriefApplicationMutation, briefID, briefAnalytics]);

  const isPreview = !!previewHash;

  if (!isPreview) {
    // Redirect users that attempt to access a teaser of a brief
    // that they are currently working on or a completed brief
    if (
      data?.brief?.submission &&
      [SubmissionState.Invited, SubmissionState.InviteAccepted].includes(
        data.brief.submission.state,
      )
    ) {
      return <Redirect to="/active-brief" />;
    } else if (data?.brief?.submission?.state === SubmissionState.Completed) {
      const redirectURI = data?.brief?.submission?.submittedAt
        ? `/previous-briefs/${briefID}/${data.brief.slug}`
        : "/";
      return <Redirect to={redirectURI} />;
    }

    // Update slug in URL if missing or incorrect
    if (data?.brief?.slug && data.brief.slug !== slug) {
      return <Redirect to={`/brief/${briefID}/${data?.brief.slug}`} />;
    }
  }

  let content;
  if (error) {
    content = <DataError error={error} />;
  } else if (loading) {
    content = <LoadingDelayed />;
  } else {
    const {
      brand,
      creatorSelectionDate,
      liveActionSurveyLink,
      numberOfCreatorApplications,
      numberOfCreators,
      paymentAmount,
      state,
      submission,
      submissionDate,
      teaser,
      title,
      type,
      viewerPaymentAmount,
    } = data!.brief;
    const { creatorsDeliverables, introduction, requirements } = teaser;

    let introductionSections: ICollatedSection[] = [];
    let introductionText: ICollatedSection[] = [];
    let introductionMedia: ICollatedSection[] = [];

    const compileTeaserContent = (
      oldData: ICollatedSection[],
      newData: Block[],
      parent: Block,
    ) => {
      return [
        ...oldData,
        {
          children: newData,
          section: {
            ...parent,
            content: newData.map((block) => block.id),
          },
        },
      ];
    };

    try {
      introductionSections = collateSections(JSON.parse(introduction));
      for (const item of introductionSections) {
        const section = item.section;
        const textContent = [];
        const mediaContent = [];

        if (item.children === undefined) {
          continue;
        }

        for (const child of item.children) {
          if (child.type === "image" || child.type === "video") {
            mediaContent.push(child);
          } else {
            textContent.push(child);
          }
        }

        introductionText = compileTeaserContent(
          introductionText,
          textContent,
          section,
        );
        introductionMedia = compileTeaserContent(
          introductionMedia,
          mediaContent,
          section,
        );
      }
    } catch {
      // Keep sections empty
    }

    const selectionDeadline = creatorSelectionDate
      ? new Date(creatorSelectionDate)
      : undefined;

    const submissionDeadline = submissionDate
      ? new Date(submissionDate)
      : undefined;

    const summaryContents = {
      assets: requirements.assets || "",
      creatorsDeliverables: creatorsDeliverables,
      featuring: requirements.featuring || "",
      location: requirements.location || "",
      numberOfCreators: numberOfCreators,
      paymentAmount: paymentAmount.amount,
      paymentCurrencyCode: paymentAmount.currencyCode,
      propsOrItems: requirements.propsOrItems || "",
      selectionDeadline: selectionDeadline,
      submissionDeadline: submissionDeadline,
      type: type,
      viewerPaymentAmount: viewerPaymentAmount.amount,
      viewerPaymentCurrencyCode: viewerPaymentAmount.currencyCode,
    };

    content = (
      <>
        <Helmet title={`${brand.name} - ${title}`} />
        {!displaySurvey && (
          <>
            {size === ScreenSize.Desktop && (
              <TeaserBanner
                numberOfCreatorApplications={numberOfCreatorApplications}
              />
            )}
            <BriefInfo tabIndex={0}>
              {size !== ScreenSize.Desktop && (
                <TeaserBanner
                  numberOfCreatorApplications={numberOfCreatorApplications}
                />
              )}
              <StyledBriefHeader
                brandImage={brand.imageURI}
                brandName={brand.name}
                briefName={title}
                briefType={type}
                id={IDS.BRIEF_TEASER.HEADER}
              />

              {type === BriefType.LiveAction ? (
                <>
                  <StyledCGCTeaserSummary {...summaryContents} />
                  <CGCIntroduction id={IDS.BRIEF_TEASER.INTRODUCTION.CONTAINER}>
                    <CGCResponsiveLayout>
                      {introductionText.map(({ children, section }, index) => (
                        <CGCTeaserSection
                          block={section}
                          disableHeader={true}
                          id={generateBlockID(
                            IDS.BRIEF_TEASER.INTRODUCTION.BLOCK,
                            index,
                          )}
                          key={section.id}
                        >
                          {children?.map((child, blockIndex) => (
                            <BlockConductor
                              block={child}
                              id={generateBlockID(
                                IDS.BRIEF_TEASER.INTRODUCTION.BLOCK,
                                index,
                                child.type,
                                blockIndex,
                              )}
                              key={child.id}
                            />
                          ))}
                        </CGCTeaserSection>
                      ))}

                      {introductionMedia.map(({ children, section }, index) => (
                        <CGCTeaserSection
                          block={section}
                          disableHeader={true}
                          id={generateBlockID(
                            IDS.BRIEF_TEASER.INTRODUCTION.BLOCK,
                            index,
                          )}
                          key={section.id}
                        >
                          {children?.map((child, blockIndex) => (
                            <BlockConductor
                              block={child}
                              id={generateBlockID(
                                IDS.BRIEF_TEASER.INTRODUCTION.BLOCK,
                                index,
                                child.type,
                                blockIndex,
                              )}
                              key={child.id}
                            />
                          ))}
                        </CGCTeaserSection>
                      ))}
                    </CGCResponsiveLayout>
                  </CGCIntroduction>
                </>
              ) : (
                <ResponsiveLayout>
                  <Introduction id={IDS.BRIEF_TEASER.INTRODUCTION.CONTAINER}>
                    {introductionSections.map(
                      ({ children, section }, index) => (
                        <TeaserSection
                          block={section}
                          disableHeader={true}
                          id={generateBlockID(
                            IDS.BRIEF_TEASER.INTRODUCTION.BLOCK,
                            index,
                          )}
                          key={section.id}
                        >
                          {children?.map((child, blockIndex) => (
                            <BlockConductor
                              block={child}
                              id={generateBlockID(
                                IDS.BRIEF_TEASER.INTRODUCTION.BLOCK,
                                index,
                                child.type,
                                blockIndex,
                              )}
                              key={child.id}
                            />
                          ))}
                        </TeaserSection>
                      ),
                    )}
                  </Introduction>

                  <StyledTeaserSummary {...summaryContents} />
                </ResponsiveLayout>
              )}
            </BriefInfo>

            {!isPreview && (
              <TeaserFooter
                applyMutationLoading={applyBriefApplicationMutationLoading}
                briefState={state}
                handleApplicationClick={handleApplicationClick}
                handleWithdrawClick={handleWithdrawClick}
                selectionDeadline={selectionDeadline}
                submissionState={submission?.state}
                successfullyApplied={successfullyApplied}
                successfullyWithdrawn={successfullyWithdrawn}
                withdrawMutationLoading={
                  withdrawBriefApplicationMutationLoading
                }
              />
            )}
          </>
        )}

        {displaySurvey && (
          <>
            <StyledBackButton
              icon={ChevronLeft}
              label={textualize("brief.survey.back")}
              onClick={() => setDisplaySurvey(false)}
            />
            <StyledWidget
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={true}
              hidden={{
                // snake case required for Typeform hidden fields
                /* eslint-disable @typescript-eslint/naming-convention */
                creator_name: `${userData!.viewer!.firstName} ${
                  userData!.viewer!.lastName
                }`,
                creator_user_id: userData!.viewer!.id,
                /* eslint-enable @typescript-eslint/naming-convention */
              }}
              hideHeaders={true}
              id={new URL(liveActionSurveyLink!).pathname.split("/")[2]}
              iframeProps={{ id: IDS.BRIEF_TEASER.CGC.SURVEY }}
              inlineOnMobile={true}
              onSubmit={() => {
                handleApplicationEvent();
                setDisplaySurvey(false);
              }}
            />
          </>
        )}
      </>
    );
  }

  return (
    <Teaser className={className} id={IDS.BRIEF_TEASER.PAGE}>
      {content}
    </Teaser>
  );
}

export default BriefTeaser;
