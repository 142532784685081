import textualize from "creators/utils/textualize";
import { Link } from "react-router-dom";
import VidsyLogo from "shared/images/vidsy-logo.svg";
import styled from "styled-components";
import { TextButton } from "vui/components/Button";

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  > span {
    margin: var(--vui-spacing-4) 0;
    white-space: break-spaces;
  }
`;

// TODO: Support localised theme modes. This is a temp fix for applying dark mode styling to this button until then.
const GoToHomepage = styled(TextButton)`
  color: rgb(var(--vui-color-on-surface-tertiary-dark));

  &:active,
  &:focus,
  &:hover {
    background-color: rgb(var(--vui-color-surface-tertiary-dark));
    color: rgb(var(--vui-color-on-surface-tertiary-dark));
  }

  &:disabled {
    background-color: none;
    color: rgba(var(--vui-color-disabled-on-surface-dark), 0.5);

    &:active,
    &:focus,
    &:hover {
      background-color: none;
      color: rgba(var(--vui-color-disabled-on-surface-dark), 0.5);
    }
  }
`;

export const StyledVidsyLogo = styled(VidsyLogo)`
  align-self: start;
  height: 2.875rem;
  justify-self: center;

  path {
    fill: rgb(var(--vui-color-on-background-dark));
  }
`;

export const Wrapper = styled.div`
  align-items: center;
  background: rgb(var(--vui-color-background-dark));
  color: rgb(var(--vui-color-on-background-dark));
  display: grid;
  grid-template-rows: auto 1fr auto;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding: var(--vui-spacing-11);
  text-align: center;
`;

const Error: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Wrapper>
    <StyledVidsyLogo />
    <Content>{children}</Content>
    <div>
      <GoToHomepage forwardedAs={Link} to="/">
        {textualize("vuiError.homepage")}
      </GoToHomepage>
    </div>
  </Wrapper>
);

export default Error;
